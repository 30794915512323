<template>
  <div>
    <b-sidebar
      id="add-new-quotation-sidebar"
      :visible="isAddNewQuotationSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => updateSideBar(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Payment Detail - {{ quotation.quotation_number }} ({{
              quotation.client_code
            }})
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <b-form-group class="" label="應付金額" label-for="total_amount">
              <b-form-input
                id="total_amount"
                v-model="quotation.total_amount"
                disabled
              />
            </b-form-group>
            <b-form-group
              class="required"
              label="支付金額"
              label-for="payment_amount"
            >
              <b-form-input
                id="payment_amount"
                v-model="quotation.payment_amount"
              />
            </b-form-group>
            <b-form-group class="required" label="支付ITEM" label-for="item">
              <b-form-input id="item" v-model="quotation.item" />
            </b-form-group>
            <b-form-group
              class="required"
              label="支付方法"
              label-for="payment_method_id"
            >
              <v-select
                v-model="quotation.payment_method_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentOptions"
                label="method_name"
                :reduce="(option) => option.id"
                :clearable="false"
              >
              </v-select>
            </b-form-group>
            <!-- Description -->
            <b-form-group
              class="payment_date required"
              label="支付日期"
              label-for="payment_date"
            >
              <b-form-datepicker
                id="payment_date"
                placeholder="Choose a date"
                local="en"
                v-model="quotation.payment_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
            <b-form-group label="收據">
              <el-upload
                class="avatar-uploader"
                list-type="picture-card"
                ref="upload"
                :action="uploadUrl"
                :headers="headerObj"
                name="image_file"
                :file-list="fileList"
                multiple
                :limit="1"
                :data="quotation"
                :on-change="onChange"
                :on-remove="toggleUpload"
                :before-remove="beforeRemove"
                :auto-upload="false"
                :class="{ hideUpload: !showUpload }"
              >
                <img
                  v-if="quotation.image_path && false"
                  :src="getImgPath(quotation.image_path)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-form-group>
            <b-form-group label="Payment Remark" label-for="remark">
              <quill-editor v-model="quotation.payment_remark" />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </template>
    </b-sidebar>

    <quotation-list-filter
      :user-filter.sync="userFilter"
      :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter"
      :user-options="userOptions"
      :client-options="clientOptions"
      :client-filter.sync="clientFilter"
      :delete-filter.sync="deleteFilter"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
              <b-button
                variant="primary"
                v-if="ability.can('create', 'quotations')"
                :to="{ name: 'quotations-add' }"
              >
                <span class="text-nowrap">Add Quotation</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refQuotationListTable"
        class="position-relative"
        :items="fetchQuotations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Create Date -->
        <template #cell(created_at)="data">
          {{ getDate(data.item.created_at) }}
        </template>

        <!-- Column: Amount -->
        <template #cell(total_amount)="data">
          {{ getNumberFormat(data.item.total_amount) }}
        </template>
        <!-- Column: Amount -->
        <template #cell(total_paid)="data">
          {{ getNumberFormat(data.item.total_paid) }}
        </template>
        <!-- Column: Client Code -->
        <template #cell(client)="data">
          <b-link
            :to="{
              name: 'clients-detail',
              params: { id: data.item.client.id },
            }"
            >{{ data.item.client.client_code }}</b-link
          >
        </template>
        <!-- Column: Contract -->
        <template #cell(contract)="data">
          <b-badge class="text-capitalize" v-if="data.item.contract">
            {{ data.item.contract.contract_id }}
          </b-badge>
        </template>
        <!-- Column: IS PAID -->
        <template #cell(is_paid)="data">
          <feather-icon
            v-if="data.item.total_paid >= data.item.total_amount"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <!-- Column: IS PAID -->
        <template #cell(is_audit)="data">
          <feather-icon
            v-if="data.item.is_audit"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <!-- Column: Project -->
        <template #cell(project)="data">
          <b-badge class="text-capitalize" v-if="data.item.project">
            {{ data.item.project.project_id }}
          </b-badge>
        </template>

        <template #cell(created_by)="data">
          <span v-if="data.item.user">{{ data.item.user.name }}</span>
        </template>

        <!-- Column: Export -->
        <template #cell(export)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            :to="{ name: 'quotations-preview', params: { id: data.item.id } }"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            v-if="data.item.deleted_at == null"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                ability.can('update', 'quotations') &&
                !data.item.is_audit &&
                data.item.deleted_at == null
              "
              :to="{ name: 'quotations-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                ability.can('delete', 'quotations') &&
                data.item.deleted_at == null
              "
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalQuotations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BOverlay,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import quotationStoreModule from "../quotationStoreModule";
import useQuotationsList from "./useQuotationsList";
import QuotationListFilter from "./QuotationListFilter";

import Ripple from "vue-ripple-directive";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    BFormDatepicker,
    quillEditor,
    QuotationListFilter,
  },
  methods: {
    updateSideBar(val) {
      this.isAddNewQuotationSidebarActive = val;
    },
    resetForm() {
      this.quotation = {
        id: 0,
        application_codes: [],
        payment_method_id: [],
        application_remark: "",
      };
    },
    hide() {
      this.isAddNewQuotationSidebarActive = false;
    },
    getDate(str) {
      return str.substring(0, 10);
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    beforeRemove(file) {
      if (
        file.url ==
        process.env.VUE_APP_IMAGE_URL + this.quotation.image_path
      ) {
        this.removeExistingPic = true;
        this.quotation.image_path = null;
      }
    },
    onChange(file, fileLists) {
      this.showUpload = !this.showUpload;
      console.log(file.raw);
      this.quotation.file = file.raw;
    },
    payment(item) {
      console.log("payment", item);
      this.quotation.client_code = item.client.client_code;
      this.quotation.total_amount = item.total_amount;
      this.quotation.total_paid = item.total_paid;
      this.quotation.quotation_number = item.quotation_number;
      this.quotation.total_amount = item.total_amount;
      this.quotation.quotation_id = item.id;
      this.quotation.item =
        "The deposit payment of the quotation no. " +
        item.quotation_number +
        " of the total amount of HK$" +
        (item.total_amount - item.total_paid);
      this.quotation.payment_amount = item.total_amount - item.total_paid;
      this.quotation.payment_method_id = 1;
      this.quotation.payment_date = "";
      this.quotation.payment_remark = "";
      this.isAddNewQuotationSidebarActive = true;
    },
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          formData.append("file", this.quotation.file);
          formData.append("quotation_id", this.quotation.quotation_id);
          formData.append("payment_amount", this.quotation.payment_amount);
          formData.append("item", this.quotation.item);
          formData.append(
            "payment_method_id",
            this.quotation.payment_method_id
          );
          formData.append("payment_date", this.quotation.payment_date);
          formData.append("payment_remark", this.quotation.payment_remark);
          this.$store
            .dispatch(
              this.QUOTATION_STORE_MODULE_NAME + "/addQuotationPayment",
              formData
            )
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              //this.$emit("refetch-data");
              this.refetchData();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "Your action is final and you will not be able to retrieve the quotation.",
        showCancelButton: true,
        confirmButtonText: "刪除",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("quotation/deleteQuotation", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup() {
    const QUOTATION_STORE_MODULE_NAME = "quotation";
    const isAddNewQuotationSidebarActive = ref(false);
    const showUpload = ref(true);

    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/quotation");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const paymentOptions = ref([]);
    const fileList = ref([]);
    const quotation = ref({
      id: 0,
      application_codes: [],
      payment_method_id: [],
      application_remark: "",
    });
    const loading = ref(false);
    const blankQuotationData = {
      id: 0,
      application_codes: [],
      application_remark: "",
    };

    if (!store.hasModule(QUOTATION_STORE_MODULE_NAME))
      store.registerModule(QUOTATION_STORE_MODULE_NAME, quotationStoreModule);

    onUnmounted(() => {
      if (store.hasModule(QUOTATION_STORE_MODULE_NAME))
        store.unregisterModule(QUOTATION_STORE_MODULE_NAME);
    });

    store
      .dispatch(QUOTATION_STORE_MODULE_NAME + "/fetchPaymentMethods")
      .then((response) => {
        paymentOptions.value = response.data.paymentmethods;
      })
      .catch((error) => {
        console.log(error.response);
      });

    const {
      fetchQuotations,
      tableColumns,
      perPage,
      currentPage,
      totalQuotations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refQuotationListTable,
      ability,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      deleteFilter,
    } = useQuotationsList();

    //Filter
    const userOptions = ref([]);
    const clientOptions = ref([]);
    store
      .dispatch(QUOTATION_STORE_MODULE_NAME + "/fetchUsers", {
        is_options: true,
      })
      .then((response) => {
        userOptions.value = response.data.users;
        //console.log(languageOptions.value)
      })
      .catch((error) => {
        console.log("Error in fetching User error", error);
      });
    store
      .dispatch(QUOTATION_STORE_MODULE_NAME + "/fetchClients", {
        is_options: true,
      })
      .then((response) => {
        clientOptions.value = response.data.clients;
        //console.log(languageOptions.value)
      })
      .catch((error) => {
        console.log("Error in fetching User error", error);
      });

    return {
      QUOTATION_STORE_MODULE_NAME,
      fetchQuotations,
      tableColumns,
      perPage,
      currentPage,
      totalQuotations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refQuotationListTable,
      ability,
      isAddNewQuotationSidebarActive,
      quotation,
      loading,
      showUpload,
      uploadUrl,
      headerObj,
      fileList,
      paymentOptions,
      userOptions,
      clientOptions,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      deleteFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
